body {
  /* background-color: #253c56; */
  /* background-color: saddlebrown; */
  /* background-image: linear-gradient(135deg, #0f1116 0%, #243b55 100%); */
  /* background-image: linear-gradient(135deg, #0c0b0b 0%, #0251fc 100%); */
  background: linear-gradient(135deg, #0f1116, #122b50);
  /* background-image: radial-gradient( circle farthest-corner at 16.5% 28.1%,  rgba(15,27,49,1) 0%, rgba(0,112,218,1) 90% ); */
  min-height:100vh;
}
 
a {
  color: inherit;
}


.blinding-text {
  font-size: 3rem;
  font-weight: bold;
  position: relative;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
}

.blinding-text::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
   /* background: #111;  */
  z-index: 1;
  transform: translateX(-100%);
  animation: blindFromLeft 2s cubic-bezier(0.25, 1, 0.5, 1) forwards;
}

@keyframes blindFromLeft {
  to {
    transform: translateX(100%);
  }
}